import { Controller } from "stimulus";

function getSelectValues(select) {
  const result = [];
  if (!select.multiple) return;
  const options = select && select.options;
  if (!options) return;
  let opt;
  for (opt of options) {
    if (opt.selected && opt.value.length) {
      result.push(opt.value);
    }
  }
  return result;
}

function buildQuery(elems) {
  let filterParams = "";
  for (const elem of elems) {
    const idSplit = elem.id?.split("-");
    const id = idSplit[0];
    const idCheckbox = idSplit[1];
    const multiple = getSelectValues(elem);
    if (multiple?.length) {
      for (const single of multiple) {
        filterParams = filterParams.length
          ? `${filterParams}&${id}[]=${single}`
          : `${id}[]=${single}`;
      }
    } else if (idCheckbox && !!elem.checked) {
      filterParams = filterParams.length
        ? `${filterParams}&${id}[]=${idCheckbox}`
        : `${id}[]=${idCheckbox}`;
    } else if (!!id && !!elem.value && elem.checked != false) {
      filterParams = filterParams.length
        ? `${filterParams}&${id}=${elem.value}`
        : `${id}=${elem.value}`;
    }
  }
  return filterParams;
}

// README.md
// For form ids, respect convention: ruby_variable_name-***-(mobile/desktop)
function initFiltersFromUrl(url, elems) {
  if (!elems) return;
  const urlParams = new URL(url).searchParams;
  for (const elem of elems) {
    const id = elem.id?.split("-")[0];
    if (!id) continue;
    const singleParam = urlParams.get(id);
    const arrayParams = urlParams.getAll(`${id}[]`);
    if (!!singleParam) {
      elem.value = singleParam;
    } else if (!!arrayParams && !!elem?.options) {
      for (const opt of elem.options) {
        opt.selected = arrayParams.indexOf(opt.value) >= 0;
      }
    } else if (
      !!arrayParams &&
      arrayParams.includes(elem.value) &&
      elem.checked === false
    ) {
      for (const param of arrayParams) {
        elem.checked = true;
      }
    } else {
      elem.value = "";
    }
  }
}

export default class extends Controller {
  static targets = [
    "category",
    "brand",
    "model",
    "number_of_axles",
    "submit-search",
    "checkbox",
    "aggregations",
  ];

  initialize() {
    this.currentUrl = "";
  }

  filterResults(e) {
    const currentLocale = window.location.pathname.split("/")?.[1] || "en";
    this.currentUrl = `${
      window.location.origin
    }/${currentLocale}/filters?${buildQuery(this.aggregationsTarget)}`;
    fetch(this.currentUrl)
      .then((res) => {
        res = res.text();
        return res;
      })
      .then((html) => {
        this.aggregationsTarget.innerHTML = html;
        initFiltersFromUrl(this.currentUrl, this.aggregationsTarget?.elements);
        initSelect();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  openModal() {
    const formToInit = document.getElementById("#filters-mobile");
    initFiltersFromUrl(window.location.href, formToInit);
  }
}
